<template>
  <ion-page>
    <div class="container h-full p-4 pb-0" v-bind="$attrs">
      <feed-entry-details-modal
        :is-open="!!showItemDetails"
        :card="showItemDetails || undefined"
        ref="modal"
        @will-dismiss="showItemDetails = false"
        @add-media="(matchMomentCard) => addMedia(matchMomentCard)"
        @cards-merged="showDetailsOfCardWithId($event)"
      />

      <div class="match-archive" v-if="showMatchArchive && oldMatches.length > 0">
        <div class="text-right text-4xl close-match-archive" @click="showMatchArchive = false">
          <ion-icon class="mb-0" :icon="close"></ion-icon>
        </div>
        <match-archive :archive="oldMatches" @change-date="changeDate" @close="showMatchArchive = false" />
        <div class="vignette opacity-30"></div>
      </div>
      <div class="flex flex-col mb-4 h-full">
        <div class="feed" ref="feedContainer">
          <div v-if="!isDataAvailable && !timelineStore.isDone" class="mt-4">
            <skeletor height="65" width="100%" class="feed-shimmer" />
            <skeletor height="65" width="100%" class="feed-shimmer" />
            <skeletor height="65" width="100%" class="feed-shimmer" />
            <skeletor height="65" width="100%" class="feed-shimmer" />
          </div>
          <div class="feed-error" v-else-if="!!timelineStore.dataFetchingError">
            <ion-icon :icon="alertCircleOutline"></ion-icon>
            Ein Fehler ist beim Laden der Timeline aufgetreten.
            <ion-button @click="timelineStore.fetchData()" :disabled="timelineStore.isLoading"
              >Nochmal probieren</ion-button
            >
          </div>
          <div class="feed-empty" v-else-if="!isDataAvailable && timelineStore.isDone">
            <ion-icon :icon="alertCircleOutline"></ion-icon>
            Es scheint ruhig auf dem Spielfeld zu sein. Schau später wieder vorbei oder wähle einen anderen Zeitraum!
          </div>
          <div class="feed-container" v-else>
            <template v-for="day in Array.from(timelineStore.timelinesByDay.keys()).sort()" :key="day">
              <template v-if="timelineStore.timelinesByDay.get(day)!.length > 0">
                <div class="feed-date">
                  <span>{{
                    day === getDateKey(DateTime.now()) ? 'Heute' : getDateFromKey(day).toFormat('dd.MM.yyyy')
                  }}</span>
                </div>
                <div class="feed-status">
                  <template v-for="feedEntry in timelineStore.timelinesByDay.get(day)" v-bind:key="feedEntry.id">
                    <feed-match-moment
                      v-if="feedEntry.class === 'Match' && feedEntry.event !== 'Competition'"
                      :moment="feedEntry"
                      :data-feed-entry-type="
                        JSON.stringify(
                          {
                            event: feedEntry.event,
                            firstElementType: feedEntry.elements[0]?.$type,
                            propertiesTitle: feedEntry.properties.title,
                          },
                          null,
                          2,
                        )
                      "
                      @toggleSlideshow="activateSlideshowOverlay"
                      @addMedia="addMedia(feedEntry)"
                      @showDetails="(event) => (showItemDetails = event)"
                    />
                    <feed-news
                      v-if="isNewsCard(feedEntry)"
                      :news="feedEntry"
                      @showDetails="(event) => (showItemDetails = event)"
                    />
                  </template>
                </div>
              </template>
            </template>
          </div>
          <ion-fab horizontal="end" vertical="bottom" slot="fixed">
            <ion-fab-button color="tertiary">
              <ion-icon :icon="add"></ion-icon>
            </ion-fab-button>
            <ion-fab-list side="top">
              <ion-fab-button
                @click="
                  cardReference = undefined;
                  useAppStore().bottomDrawer = true;
                "
                color="tertiary"
              >
                <ion-icon :icon="createOutline"></ion-icon>
              </ion-fab-button>
              <ion-fab-button
                data-changeDate="click"
                data-loadOldMatches="click"
                @click="loadOldMatches"
                color="tertiary"
              >
                <ion-icon :icon="calendarOutline"></ion-icon>
              </ion-fab-button>
              <ion-fab-button @click="triggerRefresh" color="tertiary">
                <ion-icon :icon="refresh"></ion-icon>
              </ion-fab-button>
            </ion-fab-list>
          </ion-fab>
        </div>
      </div>
      <create-post-drawer :card-reference="cardReference" />
    </div>
    <div class="slider-overlay" v-if="isSliderOverlayActive">
      <swiper :initial-slide="slideshowElementIndex">
        <swiper-slide
          v-for="element in slideshowElements"
          :key="
            element &&
            element.$type &&
            (element.$type === 'Video'
              ? element.videoUrl
              : element.$type === 'Audio'
                ? element.audioUrl
                : element.imageUrl)
          "
        >
          <full-screen-media-slide :element="element" @close="isSliderOverlayActive = false" />
        </swiper-slide>
      </swiper>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { computed, nextTick, onErrorCaptured, onMounted, onUnmounted, ref, Ref, watch, watchEffect } from 'vue';
import { IonButton, IonIcon, IonPage, IonFab, IonFabButton, IonFabList } from '@ionic/vue';
import FeedMatchMoment from './feed-entries/FeedMatchEvent.vue';
import { alertCircleOutline, calendarOutline, close, refresh, add, createOutline } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import { DateTime } from 'luxon';
import { getDateFromKey, getDateKey, triggerLoad, useTimelineStore } from '@/store/timeline';
import { MediaElement } from '@/models/media-element';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import { type TimelineCardId, TimelineCard } from '@/models/timeline-card';
import CreatePostDrawer from '@/views/feed/CreatePostDrawer.vue';
import { Skeletor } from 'vue-skeletor';
import apiRequest from '@/services/apiRequest';
import MatchArchive from '@/views/feed/MatchArchive.vue';
import FeedNews from '@/views/feed/feed-entries/FeedNews.vue';
import { useRoute, useRouter } from 'vue-router';
import { NewsPostCard } from '@/models/news-post-card';
import FeedEntryDetailsModal from '@/views/feed/feed-entry-details/FeedEntryDetailsModal.vue';
import { useMagicKeys } from '@vueuse/core';
import FullScreenMediaSlide from '@/views/feed/FullScreenMediaSlide.vue';
import { isNewsCard } from '@/models/timeline';

onErrorCaptured((err) => {
  console.error(`Ein Fehler ist aufgetreten: ${err.message}`);
  return false;
});

const timelineStore = useTimelineStore();

const route = useRoute();
const startDateParam = computed(() => route.query['start']);

watch(
  startDateParam,
  (startDate) => {
    if (!startDate) {
      timelineStore.startDate = DateTime.utc().startOf('day');
    } else {
      try {
        const parsed = DateTime.fromISO(startDate as string).startOf('day');
        if (parsed.isValid) {
          timelineStore.startDate = parsed;
        }
      } catch (e) {
        // Can't be parsed, no-op
      }
    }
  },
  { immediate: true },
);

const showMatchArchive = ref(false);
const oldMatches = ref([]);
const loadOldMatches = async () => {
  showMatchArchive.value = true;
  const selectedTimeline = useAuthStore().userSettings?.selectedTimeline?.uid;
  const today = new Date().toISOString().split('T')[0];
  const requestURLGet = `api/data/fixtures/unfold?page=0&size=20&sortDesc=true&teamUid=${selectedTimeline}&to=${today}`;
  const result = await apiRequest.methods.get(requestURLGet, 'cloud');
  oldMatches.value = result.data;
  if (!result.data) {
    showMatchArchive.value = false;
  }
};

const router = useRouter();

const changeDate = (date?: DateTime) => {
  showMatchArchive.value = false;
  router.replace({ path: 'create', query: date ? { start: date.toISODate() } : {} });
};

onMounted(timelineStore.startPolling);
onUnmounted(timelineStore.stopPolling);

const isDataAvailable = computed(() => Array.from(timelineStore.timelinesByDay.values()).some((x) => x.length > 0));

const feedContainer = ref<HTMLElement>();

const isSliderOverlayActive = ref(false);

const slideshowElements = ref([]) as Ref<MediaElement[]>;
const slideshowElementIndex = ref(0);
const activateSlideshowOverlay = (elements: MediaElement[], selectedIndex: number) => {
  slideshowElements.value = elements;
  slideshowElementIndex.value = selectedIndex;
  isSliderOverlayActive.value = true;
};

const { escape } = useMagicKeys();
watchEffect(() => {
  if (escape.value) {
    isSliderOverlayActive.value = false;
  }
});

const cardReference = ref(undefined) as Ref<TimelineCard | undefined>;

const triggerRefresh = async () => await triggerLoad();

const scrollToBottom = () =>
  nextTick(() => {
    if (feedContainer.value) {
      feedContainer.value.scrollTop = feedContainer.value.scrollHeight;
    }
  });

function addMedia(feedEntry?: TimelineCard) {
  showItemDetails.value = false;
  cardReference.value = feedEntry;
  useAppStore().bottomDrawer = true;
}

watch(
  // Scroll to the bottom only while the list loads initially
  () => timelineStore.timelinesByDay,
  () => !timelineStore.isDone && scrollToBottom(),
  { deep: true, immediate: true },
);

const appStore = useAppStore();

watchEffect(() => {
  if (!appStore.bottomDrawer) {
    cardReference.value = undefined;
  }
});

const showItemDetails = ref<false | TimelineCard | NewsPostCard>(false);

onMounted(() =>
  nextTick(() => {
    if (feedContainer.value) {
      setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
  }),
);

async function showDetailsOfCardWithId(cardId: TimelineCardId) {
  // Force fetch that card because we can't react to changes in the feed
  const cardToShow = await apiRequest.methods.get<TimelineCard>(`/api/timelines/getCard?id=${cardId}`, 'cloud');
  showItemDetails.value = cardToShow.data ?? false;
}
</script>

<style lang="scss">
.slider-overlay {
  position: absolute;
  top: calc(58px + env(safe-area-inset-top));
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100% - 58px - env(safe-area-inset-top));
  background: #00000052;
  backdrop-filter: blur(10px);
  display: flex;
  z-index: 10;

  .close-overlay {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;

    ion-icon {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
  }

  .swiper {
    position: relative;
    z-index: 1;
  }
}

.feed {
  overflow-y: scroll;
  overflow-x: hidden;

  .feed-container {
    margin-bottom: 80px;
    position: relative;
    z-index: 1;
    background: #000;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .feed-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    text-align: center;
    opacity: 0.3;

    ion-icon {
      width: 60px;
      height: 60px;
    }
  }

  .feed-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    text-align: center;

    ion-icon {
      width: 60px;
      height: 60px;
    }
  }

  .feed-status {
    position: relative;
    margin-bottom: 1rem;

    &::after {
      content: '';
      position: absolute;
      height: calc(100% - 2rem);
      width: 3px;
      top: 0;
      left: 34.5px;
      background: #fff;
      z-index: -1;
    }
  }

  .wrapper {
    // Won't work nicely with the available branded match story ribbon
    //box-shadow:
    //  rgba(0, 0, 0, 0.19) 0 10px 20px,
    //  rgba(0, 0, 0, 0.23) 0 6px 6px;
    min-height: 72px;
  }

  .feed-date {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: sticky;
    top: 10px;
    z-index: 5;

    span {
      background: linear-gradient(180deg, #333333 0%, #1a1a1a 100%);
      font-weight: 600;
      border-radius: 50px;
      padding: 7px 15px;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      min-width: 120px;
      display: inline-block;
      line-height: 1;
    }
  }

  .floating-action-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    font-size: 32px;
    background: #555;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 9;
    cursor: pointer;

    &.active {
      box-shadow: var(--ion-color-primary, #3880ff) 0 0 0 2px;
    }
  }
}

.active.feed-shortcut {
  max-height: 300px;
  padding: 1rem;
}

.feed-shortcut {
  * {
    color: #fff !important;
  }

  .draggableBottomDrawer {
    &__element {
      background: #191e24;
    }
  }

  h2 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .feed-shortcut-body {
    display: flex;
    justify-content: center;
    /*overflow-y: scroll;
    scroll-snap-type: y mandatory;*/

    &::-webkit-scrollbar {
      display: none;
    }

    .feed-add-element {
      width: 100px;
      min-width: 100px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      scroll-snap-align: start;
      cursor: pointer;

      &:hover {
        ion-icon,
        span {
          color: var(--ion-color-primary, #3880ff) !important;
          fill: var(--ion-color-primary, #3880ff) !important;
        }
      }

      .icon-wrapper {
        width: 68px;
        aspect-ratio: 1 / 1;
        display: flex;
        padding: 16px;
        background: #373737;
        border-radius: 24px;
        margin-bottom: 0.5rem;

        ion-icon {
          margin-bottom: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.active.feed-shortcut {
  margin-bottom: 0;
}

.active.feed-overlay {
  opacity: 1 !important;
  pointer-events: all !important;
}

.ios {
  .feed-type-media {
    div {
      border-radius: 50% !important;

      .progressbar {
        bottom: 3px !important;
      }
    }
  }
}

.feed-shimmer {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.load-old-matches {
  position: absolute;
  background: #555555;
  color: rgb(255, 255, 255);
  z-index: 9;
  bottom: 80px;
  right: 16px;
  line-height: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.match-archive {
  position: absolute;
  background: #282828;
  color: rgb(255, 255, 255);
  padding: 0 20px 20px;
  z-index: 99999;
  top: calc(58px + env(safe-area-inset-top));
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;

  .close-match-archive {
    margin-right: -10px;
  }

  .vignette {
    position: fixed;
  }
}
</style>
