import { ref } from 'vue';
import { App } from '@capacitor/app';

const isFocused = ref(false);

// Capacitor does not allow removing listeners again, so we only add a single listener once.
App.addListener('appStateChange', (state) => {
  isFocused.value = state.isActive;
});

export default function useAppFocus() {
  return isFocused;
}
