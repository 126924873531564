<template>
  <div class="rounded-full bg-white w-12 overflow-hidden">
    <img
      v-if="leaguesImageLoaded"
      :class="{ hidden: !leaguesImageLoaded }"
      :src="src"
      @error="leaguesImageLoaded = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

defineProps<{ src: string }>();

const leaguesImageLoaded = ref(true);
</script>

<style scoped></style>
