<template>
  <div class="match-wrapper">
    <swiper ref="swiperInstance" :breakpoints="sliderBreakpoints">
      <swiper-slide>
        <match-reporter />
      </swiper-slide>
      <swiper-slide>
        <match-lineup />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, ref, watchEffect } from 'vue';
import matchReporter from '@/views/MatchReporter.vue';
import matchLineup from '@/views/MatchLineup.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { emitter } from '@/bus';
import useAppFocus from '@/composable/useAppFocus';

const sliderBreakpoints = ref({
  0: {
    slidesPerView: 1,
    spaceBetween: 50,
    initialSlide: 0,
    touchRatio: 0.05,
    longSwipesRatio: 1,
  },
  1024: {
    slidesPerView: 2,
    spaceBetween: 50,
    touchRatio: 0.05,
    longSwipesRatio: 1,
  },
});
const swiperInstance = ref<any | null>(null);
const showMatchReporter = ref(true);

const isFocused = useAppFocus();

watchEffect(async () => {
  if (isFocused.value) {
    showMatchReporter.value = false;
    await nextTick();
    showMatchReporter.value = true;
  }
});

onBeforeMount(() => emitter.on('handleSlideTo', (e) => handleSlideTo(e)));

onBeforeUnmount(() => emitter.all.delete('handleSlideTo'));

function handleSlideTo(i: number) {
  swiperInstance.value?.slideTo(i);
}
</script>

<style lang="scss">
.match-wrapper {
  .swiper-wrapper {
    .swiper-slide {
      overflow: scroll;
      height: 100vh;
      align-items: flex-start;
      max-height: calc(100vh - var(--bottom-nav-height, 56px));
      /*@media (max-width: 1023px) {
          max-height: calc(100vh - var(--bottom-nav-height, 56px));
        }

        @media (min-width: 1024px) {
          max-height: calc(100vh - var(--bottom-nav-height, 56px));
        }*/

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.plt-mobileweb {
  .match-wrapper {
    .swiper-wrapper {
      .swiper-slide {
        max-height: calc(100vh - (31px + var(--bottom-nav-height, 56px)));
      }
    }
  }
}
</style>
