<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="open-menu--mobile" @click="openMenu()">
          <ion-icon slot="start" :icon="menu" />
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-thumbnail v-if="badgeUrl" slot="end" class="badge" @click="() => router.push('/switchclub')">
          <img :src="badgeUrl" />
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>
    <slot></slot>
  </ion-page>
</template>

<script lang="ts" setup>
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  menuController,
} from '@ionic/vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { menu } from 'ionicons/icons';
import { emitter } from '@/bus';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';

const router = useRouter();

defineProps<{ pageTitle: string }>();

function openMenu() {
  menuController.open('app-menu');
}

const badgeImage = ref('');

onMounted(() =>
  emitter.on('changeBadge', (e) => {
    badgeImage.value = e;
  }),
);

onUnmounted(() => emitter.all.delete('changeBadge'));

const badgeUrl = computed(() =>
  badgeImage.value !== '' ? badgeImage.value : useAuthStore().userSettings.activeClub?.badgeUrl || '',
);
</script>

<style lang="scss">
.open-menu {
  &--mobile {
    padding-left: 13px;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}

.badge {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
</style>
