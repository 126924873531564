import { defineStore } from 'pinia';
import { fbLogout, fbSignIn } from './firebase';
import { useAppStore } from '@/store/app';
import router from '@/router';
import { computed, ref } from 'vue';
import { typedFromEntries, typedObjectKeys } from '@/utils/typed-stdlib';
import { resetAllPiniaStores } from '@/utils/pinia-utils';
import autocloseOverlaysService from '@/services/autocloseOverlaysService';
import type { Club } from '@/models/club';
import type { Team } from '@/models/team';
import apiRequest from '@/services/apiRequest';
import firebase from 'firebase';

type EntityUid = string; // Usually a Timeline ID

/** A mapping from "action" to available entity IDs */
type PermissionsForEntities = {
  club_manage: EntityUid[];
  club_read: EntityUid[];
  club_write: EntityUid[];
  competition_read: EntityUid[];
  fixtures_modify: EntityUid[];
  media_autoaudio: EntityUid[];
  media_autoaudio_protected: EntityUid[];
  media_upload: EntityUid[];
  media_uploadinbox: EntityUid[];
  team_read: EntityUid[];
  team_write: EntityUid[];
  timeline_read: EntityUid[];
  timeline_write: EntityUid[];
  user_create: EntityUid[];
  user_remove: EntityUid[];
  user_roles: EntityUid[];
};

/** Response of profiles/.../manage/permissions/actions/list */
export const ALL_PERMISSIONS = {
  club_manage: 'Verwaltungsfunktionen für den Verein',
  club_read: 'Lesezugriff auf Vereinsdaten',
  club_write: 'Ändern der Vereinsdaten',
  competition_read: 'Wettbewerb(e) auflisten',
  fixtures_modify: 'Ereignisse erstellen',
  media_autoaudio: 'AutoAudio-Medien verwalten',
  media_autoaudio_protected: 'Geschützte (von LEAGUES bereitgestellte) AutoAudio-Medien verwalten',
  media_upload: 'Medien in das LEAGUES-CDN hochladen',
  media_uploadinbox: 'In den Medieneingang des CDN hochladen',
  team_read: 'Lesezugriff auf Teamdaten',
  team_write: 'Teamdaten ändern',
  timeline_read: 'Zugriff auf die Zeitleiste',
  timeline_write: 'Zeitleistendaten ändern',
  user_create: 'Ein neues Ligenkonto für einen Benutzer erstellen',
  user_remove: 'Ein Benutzerkonto aus dem System entfernen',
  user_roles: 'Rollen eines Benutzers verwalten',
} as const satisfies Record<keyof PermissionsForEntities, string>;

const PERMISSIONS_DEFAULTS = Object.freeze(
  typedFromEntries(
    typedObjectKeys(ALL_PERMISSIONS).map((x) => {
      // Every permission is initialized with an empty array
      return [x, []];
    }),
  ),
);

export const ALL_ROLES = {
  member: 'Mitglied',
  supporter: 'Supporter',
  staff: 'Mitarbeiter',
  manager: 'Management', // TODO: rename
  creator: 'Content Creator',
  team: 'Teammitglied',
  mediacom: 'Medien und Kommunikation',
  teammanager: 'Team-Manager',
  clubmanager: 'Club-Manager',
  cortextdatamanager: 'Cortex Data Manager',
} as const;

export type RoleKey = keyof typeof ALL_ROLES;

export type RoleLevel = 'Full' | 'Limited' | 'Minimal' | 'None';
export const ALL_ROLE_LEVELS = ['None', 'Minimal', 'Limited', 'Full'] as const satisfies Readonly<RoleLevel[]>;

export type UserRole = { entityId?: string; roleKey: keyof typeof ALL_ROLES; level: RoleLevel };

export type Profile = {
  userId: string;
  handle: string;
  official: boolean;
  avatarImageUrl: string;
  avatarThumbnailUrl: string;
};

type UserSettings = {
  activeClub: Club & {
    // TODO: Remove this in favor of just accessing the uid directly
    clubId: string;
    isTeam: boolean;
    shortenedName: string;
    clubTeams: Team[];
  };
  selectedTimeline: {
    uid?: string;
    name?: string;
  };
};

export const useAuthStore = defineStore('authStore', () => {
  const isUserAuthenticated = ref(false);
  const userUid = ref('');
  const profile = ref<Profile | undefined>();
  const accessToken = ref<string | undefined>();
  const error = ref<string | undefined>();
  const userSettings = ref<UserSettings>({
    activeClub: {} as UserSettings['activeClub'],
    selectedTimeline: {
      uid: undefined,
    },
  });

  const selectedTimelineUid = computed(() => userSettings.value?.selectedTimeline?.uid as string);
  const activeClubUid = computed(() => userSettings.value?.activeClub?.uid);

  const userEmail = ref<string | undefined>();
  const cachedPermissions = ref<PermissionsForEntities>({ ...PERMISSIONS_DEFAULTS });

  const roles = ref<UserRole[] | undefined>(undefined);

  const userError = computed(() => !!error.value);

  /**
   * listens for state changes, ie a user logging in or out
   * and if logging in, loading the associated profile info
   */
  async function logInUser(email: string, password: string) {
    try {
      await fbSignIn(email, password);
      //user = true;
      error.value = '';
      return true;
    } catch (e: any) {
      // noinspection ES6MissingAwait
      logoutUser();
      isUserAuthenticated.value = false;
      error.value = e.code;
      return false;
    }
  }

  async function logoutUser() {
    await fbLogout();
    await autocloseOverlaysService.trigger();
    resetAllPiniaStores();
    await router.replace('/login');
    return true;
  }

  async function getPermissions(action: keyof PermissionsForEntities) {
    if (!accessToken.value) {
      throw Error('No Bearer token available');
    }

    const response = await apiRequest.methods.get(`permissions/allowedEntities?action=${action}`, 'profiles');

    if (response.data) {
      cachedPermissions.value[action] = response.data;
    }
  }

  async function retrieveAllUserRoles() {
    if (!accessToken.value) {
      throw Error('No Bearer token available');
    }

    const response = await apiRequest.methods.get(`roles/list`, 'profiles');

    response.data && (roles.value = response.data);
  }

  async function retrieveAllPermissions() {
    await Promise.all(typedObjectKeys(ALL_PERMISSIONS).map(getPermissions));
  }

  async function retrieveAllPermissionsAndUserRoles() {
    await Promise.all([retrieveAllPermissions(), retrieveAllUserRoles()]);
  }

  /**
   * Checks whether the user is allowed to access the timeline with the given entityId (usually a club timeline).
   */
  async function ensureUserHasAccessToEntity(entityId: string) {
    if (!entityId.length) {
      return false;
    }

    await retrieveAllPermissions();
    let userHasAccess = false;
    typedObjectKeys(cachedPermissions.value).forEach((key) =>
      cachedPermissions.value[key].forEach((permission: string) => {
        if (permission === entityId || permission === '00000000-0000-0000-0000-000000000000') {
          userHasAccess = true;
        }
      }),
    );

    if (!userHasAccess) {
      userSettings.value.selectedTimeline.uid = undefined;
      const bannerObject = {
        success: false,
        message: 'Dir fehlen die Rechte, um auf diese Timeline zuzugreifen',
      };
      useAppStore().addBanner(bannerObject);
      await router.push('/switchclub');
    }
    return userHasAccess;
  }

  /**
   * Checks whether the user has permissions to access this {@link action} on a club, team, or even a single
   * match timeline.
   * @param action The permission to check for
   * @param entityId The UID of the entity (a club, team, or match timeline)
   */
  async function userHasPermissionForEntity(action: keyof PermissionsForEntities, entityId: string) {
    await getPermissions(action);
    return (
      cachedPermissions.value[action].includes(entityId) ||
      // Admin
      cachedPermissions.value[action].includes('00000000-0000-0000-0000-000000000000')
    );
  }

  function userHasGlobalPermission(action: keyof PermissionsForEntities): boolean {
    return cachedPermissions.value[action].includes('00000000-0000-0000-0000-000000000000');
  }

  /**
   * Checks whether the user has permissions to access this {@link action} on the currently selected timeline
   * @param action The permission to check for
   */
  function userHasPermission(action: keyof PermissionsForEntities): 'global' | true | false {
    if (!selectedTimelineUid.value) {
      return false;
    }

    // We expect all permissions referencing the currently selected timeline to already have been loaded here.
    if (userHasGlobalPermission(action)) {
      return 'global' as const;
    }

    return cachedPermissions.value[action].includes(selectedTimelineUid.value);
  }

  function getPermittedEntities(action: keyof PermissionsForEntities) {
    return cachedPermissions.value[action].filter((x) => x !== '00000000-0000-0000-0000-000000000000');
  }

  function userHasRole(role: keyof typeof ALL_ROLES) {
    return roles.value?.some((x) => x.roleKey === role) ?? false;
  }

  function getRoleInfo(role: keyof typeof ALL_ROLES) {
    return roles.value?.find((x) => x.roleKey === role);
  }

  async function onAuthenticated({ uid, email }: firebase.User, token: string) {
    userUid.value = uid;
    userEmail.value = email!;
    accessToken.value = token;
    try {
      await retrieveAllPermissionsAndUserRoles();
      if (Array.isArray(roles.value)) {
        if (roles.value.length === 0) {
          isUserAuthenticated.value = false;
          accessToken.value = '';
          error.value = 'auth/user-no-access';

          const bannerObject = {
            success: false,
            message: 'Deinem Account fehlt die Berechtigung, diese App zu nutzen!',
          };
          useAppStore().addBanner(bannerObject);

          return false;
        }
      }
      isUserAuthenticated.value = true;
      return true;
    } catch (err: any) {
      isUserAuthenticated.value = false;
      accessToken.value = '';
      error.value = 'auth/user-no-access';
      return false;
    }
  }

  return {
    onAuthenticated,
    logInUser,
    logoutUser,
    ensureUserHasAccessToEntity,
    userHasPermissionForEntity,
    userHasPermission,
    userHasGlobalPermission,
    getPermittedEntities,
    userHasRole,
    getRoleInfo,
    userEmail,
    isUserAuthenticated,
    userError,
    userUid,
    profile,
    userSettings,
    accessToken,
    error,
    permissions: cachedPermissions,
    selectedTimelineUid,
    activeClubUid,
  };
});
