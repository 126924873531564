import firebase from 'firebase/app';
import 'firebase/auth';
import { useAuthStore } from '@/store/auth';
import router from '@/router';

const configOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// In vite HMR, we may already have initialized firebase. Check for this and only initialize if not.
if (!firebase.apps.length) {
  firebase.initializeApp(configOptions);
}

export async function fbSignIn(email: string, password: string) {
  const loginSuccessful = await firebase.auth().signInWithEmailAndPassword(email, password);
  if (loginSuccessful) {
    const authStore = useAuthStore();
    authStore.isUserAuthenticated = true;
    return true;
  } else {
    return false;
  }
}

export async function fbLogout() {
  await firebase.auth().signOut();
  const authStore = useAuthStore();
  authStore.isUserAuthenticated = false;
  authStore.accessToken = '';
  localStorage.clear();
  sessionStorage.clear();
  return true;
}

export function initFbAuthEventListeners() {
  return new Promise<void>((resolve) => {
    const authStore = useAuthStore();

    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        return resolve();
      }

      try {
        const accessToken = await user.getIdToken(true);

        const authSuccessful = await authStore.onAuthenticated(user, accessToken);

        if (!authSuccessful) {
          await firebase.auth().signOut();
          return;
        }

        if (router.currentRoute.value.path === '/login' && authStore.isUserAuthenticated) {
          await router.push('/');
        }
      } finally {
        resolve();
      }
    });

    firebase.auth().onIdTokenChanged(async (user) => {
      if (!user) {
        return;
      }

      authStore.accessToken = await user.getIdToken();
    });
  });
}
